<template>
  <div class="pa-4">
    <tp-text-field
      v-model="homeSettingBanner.title"
      class="mb-2"
      hide-details="auto"
      label="Tiêu đề"
      placeholder="Nhập tiêu đề ngắn gọn"
      validate="required"
    ></tp-text-field>

    <v-checkbox
      v-model="homeSettingBanner.is_show_description"
      class="mt-0 mb-3"
      hide-details
      label="Hiển thị description"
    ></v-checkbox>

    <tp-text-field
      v-if="homeSettingBanner.is_show_description"
      v-model="homeSettingBanner.description"
      class="mb-2"
      hide-details="auto"
      label="Mô tả"
      placeholder="Nhập mô tả ngắn gọn"
      validate="required"
    ></tp-text-field>

    <v-checkbox
      v-model="homeSettingBanner.is_show_cta_button"
      class="mt-0 mb-3"
      hide-details
      label="Hiển thị CTA button"
    ></v-checkbox>

    <input-color-picker
      v-if="homeSettingBanner.is_show_cta_button"
      v-model="homeSettingBanner.color"
      :rules="[v => !!v || 'Không được bỏ trống']"
      class="mb-6"
      hide-details="auto"
      label="Màu text"
    ></input-color-picker>

    <input-color-picker
      v-if="homeSettingBanner.is_show_cta_button"
      v-model="homeSettingBanner.button_color"
      :rules="[v => !!v || 'Không được bỏ trống']"
      class="mb-6"
      hide-details="auto"
      label="Màu button"
    ></input-color-picker>

    <input-color-picker
      v-model="homeSettingBanner.background_color"
      class="mb-6"
      hide-details="auto"
      label="Màu background"
    ></input-color-picker>

    <tp-text-field
      v-if="homeSettingBanner.is_show_cta_button"
      v-model="homeSettingBanner.button_text"
      class="mb-6"
      hide-details="auto"
      label="CTA button text"
      placeholder="Nhập nội dung"
      validate="required"
    ></tp-text-field>

    <tp-text-field
      v-if="homeSettingBanner.is_show_cta_button"
      v-model="homeSettingBanner.button_url"
      class="mb-6"
      dense
      hide-details="auto"
      label="Đường dẫn khi bấm vào button"
      outlined
      placeholder="Nhập nội dung"
      prepend-inner-icon="mdi-link"
      validate="required|url"
    ></tp-text-field>

    <tp-text-field
      v-model="homeSettingBanner.banner_url"
      class="mb-2"
      dense
      hide-details="auto"
      label="Đường dẫn khi bấm vào banner"
      outlined
      placeholder="Nhập nội dung"
      prepend-inner-icon="mdi-link"
      validate="required|url"
    ></tp-text-field>

    <div>
      <div class="mb-2">Cover</div>

      <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
        <div>
          Không yêu cầu kích thước, vui lòng kiểm tra kích thước cho từng
          website.
        </div>
      </div>

      <tp-input-image
        :acceptedFileTypes="['image/*']"
        :maxFileSize="500 * 1024"
        :src.sync="homeSettingBanner.cover"
        allowCompress
        allowFileSize
        allowFileTypeValidation
        class="flex-grow-1"
        forceType="jpeg"
        required
        style="height: 213px; max-width: 800px; width: 100%"
      ></tp-input-image>
    </div>

    <div class="mt-6">
      <div class="mb-2">Cover mobile</div>

      <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
        <div>
          Không yêu cầu kích thước, vui lòng kiểm tra kích thước cho từng
          website.
        </div>
      </div>

      <tp-input-image
        :acceptedFileTypes="['image/*']"
        :maxFileSize="500 * 1024"
        :src.sync="homeSettingBanner.cover_mobile"
        allowCompress
        allowFileSize
        allowFileTypeValidation
        class="flex-grow-1"
        forceType="jpeg"
        style="height: 108px; max-width: 320px; width: 100%"
      ></tp-input-image>
    </div>

    <div class="mt-6">
      <div class="mb-2">Thumbnail</div>

      <div class="amber lighten-5 rounded text-body-2 px-2 py-1 mb-2">
        Kích thước ảnh yêu cầu
        <span class="font-weight-bold">là 1200 x 630 px</span>
      </div>

      <tp-input-image
        :acceptedFileTypes="['image/*']"
        :maxFileSize="500 * 1024"
        :src.sync="homeSettingBanner.thumbnail"
        allowCompress
        allowFileSize
        allowFileTypeValidation
        class="flex-grow-1"
        forceType="jpeg"
        required
        style="height: 420px; max-width: 800px; width: 100%"
      ></tp-input-image>
    </div>
  </div>
</template>

<script>
import InputColorPicker from "./InputColorPicker.vue";

export default {
  components: {
    InputColorPicker
  },
  data() {
    return {
      color: "#1976D2FF"
    };
  },
  computed: {
    homeSettingBanner() {
      const { BANNER } = this.$store.getters["HOME_SETTING/homeSetting"];
      return BANNER;
    }
  }
};
</script>

<style></style>
